// Dependencies
import { useState } from "react";
import { debounce } from "underscore";

import "./NavBar.css";

export default function NavBar() {
    const [hover, setHover] = useState(false);
    const [active, setActive] = useState([true, false, false, false, false, false])

    const NavLinks = [
        { name: "Experience", subLinks: null },
        { name: "Projects", subLinks: ["Github", "CodePen", "Replit"] },
        { name: "Education", subLinks: null },
        { name: "Certifications", subLinks: null },
        { name: "Life", subLinks: ["Hobbies", "Awards"] },
    ]

    // NavLink Abstraction
    function NavLink(props) {
        const [active, setActive] = useState( props.active );

        function handleClick(i) {
            setActive(active.map( (_, j) => i==j ));
        }

        return (
            <div>
                <a className={`${active && "active"} nav-link ps-0`} href={`#${props.name}`} onClick={ () => props.handleClick(props.idx) }>{props.name}</a>
                { active && props.subLinks && props.subLinks.map((name, idx) =>
                    <NavLink {...{
                        name,
                        idx,
                        active: active[idx],
                        handleClick: handleClick
                    }} />
                )}
            </div>
        )
    }

    function handleClick(i) {
        setActive(active.map( (_, j) => i==j ));
    }

    return (
        <div id="Navbar" className="container-fluid">
            <nav className="position-sticky sticky-top navbar navbar-dark d-flex flex-column align-items-start">
                <div id="name" className="navbar-brand d-flex flex-column" onMouseEnter={ () => setHover(true) } onMouseLeave={ debounce(() => setHover(false), 1000) } >
                    <div id="profile-card" className="d-flex align-items-center">
                        <h3>Paul</h3>
                        <div id="wrapper" className={hover? "slide-out": "slide-in"}>
                            <img src="./Resources/pfp.jpg" className="img-fluid rounded w-100" alt="Portfolio Picture" />
                        </div>
                    </div>
                </div>
                <h6 className="text-nowrap">Student | Software Engineer</h6>
                <div id="links" className="pt-4">
                    { NavLinks.map((entry, idx) =>
                        <NavLink {...{
                            name: entry.name,
                            subLinks: entry.subLinks,
                            idx,
                            active: active[idx],
                            handleClick
                        }} />
                    )}
                </div>
            </nav>
        </div>
    )
}
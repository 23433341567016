// Components
import ProjectEntry from "./ProjectEntry";

export default function Github() {

    return (
        <div id="Github" className="section">
            <h4>Github</h4>
            <div className="d-flex justify-content-center">
                <ProjectEntry {...{
                    id: "fish-sim",
                    name: "AI Fish Simulator",
                    link: { href: "https://github.com/PaulPresident/AI-Fish-Simulator", title: "Click to view project on Github" },
                    img: { src: "./Resources/Projects/Github/ai-fish-simulator.png", alt: "AI Fish learn to find food." }
                }} />
            </div>
        </div>
    )
}
// Components
import CodePen from "./CodePen";
import Github from "./Github";
import Replit from "./Replit";

import "./Projects.css";

export default function Projects() {
    return (
        <div id="Projects">
            <h3>Projects</h3>
            <Github />
            <CodePen />
            <Replit />
        </div>
    )
}
import "./Console.css";

export default function Console(props) {

    return (
        <div id="Console" className="w-100">
            <div className="d-flex align-items-center">
                <h4 className="console-text text-nowrap">C:\{props.route.join("\\")}&gt;</h4>
                <h4 className="w-100"><input id="console-input" className="console-text w-100 ms-1" value={props.input} placeholder='try "cd ./Experience/" or "cd.."' onChange={ (e) => props.setInput(e.currentTarget.value) }/></h4>
            </div>
            { props.commandError &&
                <div className="d-flex align-items-start">
                    <h4 className="console-text console-warning">{props.commandError}</h4>
                    <button className="bg-transparent border-0 console-warning-x fa fa-x" onClick={ () => props.setCommandError("") }></button>
                </div>
            }
        </div>
    )
}
export default function EducationEntry(props) {
    return (
        <div id={props.id} className="pt-3 pe-4">
            <div className="row">
                <div className="col-2">
                    <img src={props.img.src} className="w-100 img-fluid rounded" alt={props.img.alt} />
                </div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-9">
                            <h4 id="institution">{props.institution}</h4>
                        </div>
                        <div className="col-3 text-end">
                            <h5 id="location"><i className="fa fa-map-marker me-2" aria-hidden="true"></i>{props.location}</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <h6 id="degree">{props.degree}</h6>
                        </div>
                        <div className="col-3 text-end">
                            <h6 id="date">{props.startDate} - {props.endDate}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <ul id="description">
                                { props.hasOwnProperty("desc") && props.desc.map(entry => <li>{entry}</li>) }
                                { props.hasOwnProperty("courses") && props.courses.map(entry => <li>{entry.name}&nbsp;{entry.number}</li>) }
                            </ul>
                        </div>
                        <div className="col-3 text-end">
                            <h6>GPA: {props.gpa}/4.0</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default function ExperienceEntry(props) {
    return(
        <div id={props.id} className="pt-3 pe-4">
            <div className="row">
                <div className="d-flex col-2">
                    <img src={props.img.src} className="align-self-center w-100 img-fluid rounded" alt={props.img.alt} />
                </div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-9">
                            <h4 id="employer">{props.employer}</h4>
                        </div>
                        <div className="col-3 text-end">
                            <h5 id="location"><i className="fa fa-map-marker me-2" aria-hidden="true"></i>{props.location}</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <h6 id="position">{props.position}</h6>
                        </div>
                        <div className="col-3 text-end">
                            <h6 id="date">{props.startDate} - {props.endDate || "Ongoing"}</h6>
                        </div>
                    </div>
                    <ul id="description">
                        { props.desc && props.desc.map(entry => <li>{entry}</li>) }
                    </ul>
                </div>
            </div>
        </div>
    )
}
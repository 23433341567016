import { useState } from "react";

export default function ProjectEntry(props) {
    const [hover, setHover] = useState(false);

    return (
        <a
            id={props.id}
            className="project-container d-flex flex-column justify-content-center mx-3 rounded"
            href={props.link.href}
            target="_blank"
            title={props.link.title}
            onMouseOver={() => { setHover(true) }}
            onMouseOut={() => { setHover(false) }}
        >
            <img src={props.img.src} alt={props.img.alt} />
            <div className="my-3 text-center">
                { hover && <span>&lt;</span> }
                &nbsp;{props.name}&nbsp;
                { hover && <span>/&gt;</span> }
            </div>
        </a>
    )
}
import ExperienceEntry from "./ExperienceEntry";

export default function Experience() {
    return (
        <div id="Experience" className="section">
            <h3>Experience</h3>
            <ExperienceEntry {...{
                id: "sheetz",
                img: { src: "/Resources/Experience/sheetz.png", alt: "Company Logo" },
                employer: "Sheetz",
                location: "Remote",
                position: "Software Engineer Intern",
                startDate: "May 2023",
                endDate: "Present",
                desc: [
                    "Developed an executive dashboard from the ground up providing C‑level executives and stakeholders with essential business intelligence expressed graphically. The dashboard consolidated and arranged metrics, KPIs, and performance scorecards on a single screen by pulling data in real‑time from various sources such as Rally, Teradata, AWS, and GitLab. (TypeScript, React, Storybook, Go)",
                    "Improved UX by creating a widget creator and implementing a drag‑and‑drop functionality allowing users to create near‑infinite combinations of widget components with ease.",
                    "Improved app accessibility by implementing a sidebar minimize to allow for smaller types of screen sizes such as tablets, and increased responsiveness of the resizing operation by 200ms.",
                    "Achieved 90% documentation of frontend components by using storybook, and ensured each UI component was as isolated as possible increasing the efficiency of QA operations and debugging sessions.",
                    "Led my team of 6 interns for a week as part of a team lead rotation schedule. Conducted daily stand‑ups to delegate tasks, identify blockers, and maintain steady progress throughout the sprint."
                ]
            }} />
            <ExperienceEntry {...{
                id: "aeyesafe",
                img: { src: "/Resources/Experience/aeyesafe.svg", alt: "Company Logo" },
                employer: "Aeyesafe Inc",
                location: "Remote",
                position: "Software Engineer Intern",
                startDate: "Apr 2022",
                endDate: "Dec 2022",
                desc: [
                    "Worked alongside UI/UX team to implement and debug modern UI features and components and reported directly to the CEO with weekly progress reports and demos of my completed work (JavaScript, React, HTML and CSS).",
                    "Led team of 5+ interns assessing the development of the alpha version of Sleepsense Portal and reviewed PRs & provided feedback before final merge to enable safer and faster feature delivery.",
                    "Decreased delay of accessing new information in backend servers from 1 minute down to seconds by implementing Firebase Cloud Messaging.",
                    "Throttled repetitive calls to backend APIs to decrease backend server load and improve frontend responsiveness by 50%."
                ]
            }} />
            <ExperienceEntry {...{
                id: "stauffers",
                img: { src: "/Resources/Experience/stauffers.png", alt: "Stauffer's of Kissel Hill Front of Store" },
                employer: "Stauffer's of Kissel Hill",
                location: "Lititz",
                position: "Sales Associate",
                startDate: "Jun 2021",
                endDate: "Aug 2022",
                desc: [
                    "Recorded and processed all orders in an organized and accurate manner.",
                    "Settled customer disputes in a professional and pleasant manner.",
                    "Adapted to changing environment after assessing customer needs and staff on hand.",
                    "Trained new staff on register and provided feedback on areas of improvement."
                ]
            }} />
        </div>
    )
}
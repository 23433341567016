// Dependencies
import { useEffect, useState } from 'react';
import { debounce, isEqual, throttle } from 'underscore';

// Components
import Home from './Home/Home';
import Console from './Console/Console';

import './App.scss';

// directory structure
const directory = {
    Users: {
        route: ["Users"],
        Paul: {
            route: ["Users", "Paul"],
            Experience: {
                route: ["Users", "Paul", "Experience"],
                "Aeyesafe": { route: ["Users", "Paul", "Experience", "Aeyesafe"] },
                "Stauffer's of Kissel Hill": { route: ["Users", "Paul", "Experience", "Stauffer's of Kissel Hill"] }
            },
            Projects: {
                route: ["Users", "Paul", "Projects"],
                "Github": { route: ["Users", "Paul", "Projects", "Github"] },
                "CodePen": { route: ["Users", "Paul", "Projects", "CodePen"] },
                "Replit": { route: ["Users", "Paul", "Projects", "Replit"] }
            },
            Education: {
                route: ["Users", "Paul", "Education"],
                "Temple University": { route: ["Users", "Paul", "Education", "Temple University"] },
                "Manheim Township High School": { route: ["Users", "Paul", "Education", "Manheim Township High School"] }
            },
            Certifications: {
                path: ["Users", "Paul", "Certifications"],
                "freeCodeCamp": { path: ["Users", "Paul", "Certifications", "freeCodeCamp"] }
            },
            Life: {
                route: ["Users", "Paul", "Life"],
                Hobbies: {
                    route: ["Users", "Paul", "Life", "Hobbies"],
                    "Cooking": { route: ["Users", "Paul", "Life", "Hobbies", "Cooking"] }
                },
                Awards: {
                    route: ["Users", "Paul", "Life", "Awards"],
                    "Mahragan El-Keraza": { route: ["Users", "Paul", "Life", "Awards", "Mahragan El-Keraza"] }
                }
            }
        }
    }
}

function App() {
    const [vScroll, setVScroll] = useState(0);      // scroll position >> sets routing
    const [route, setRoute] = useState(["Users", "Paul"]);       // route instructions >> used to navigate directory
    const [input, setInput] = useState("");     // user cmd input
    const [commandError, setCommandError] = useState();
    const [enter, setEnter] = useState();   // timestamp of last enter keypress
    const [windowSize, setWindowSize] = useState();     // timestamp of last window resize
    const [scrollPosition, setScrollPosition] = useState({"Paul": 0});      // scroll positions of all elements on doc

    // to set path based on scroll
    const subDir = ["Welcome", "aeyesafe", "stauffers", "Github", "CodePen", "Replit", "temple", "mtwp", "freecodecamp", "cooking", "mahragan-el-keraza"]
    const routing = {
        "Welcome": ["Users", "Paul"],
        "aeyesafe": ["Users", "Paul", "Experience", "Aeyesafe"],
        "stauffers": ["Users", "Paul", "Experience", "Stauffer's of Kissel Hill"],
        "Github": ["Users", "Paul", "Projects", "Github"],
        "CodePen": ["Users", "Paul", "Projects", "CodePen"],
        "Replit": ["Users", "Paul", "Projects", "Replit"],
        "temple": ["Users", "Paul", "Education", "Temple University"],
        "mtwp": ["Users", "Paul", "Education", "Manheim Township High School"],
        "freecodecamp": ["Users", "Paul", "Certifications", "freeCodeCamp"],
        "cooking": ["Users", "Paul", "Life", "Hobbies", "Cooking"],
        "mahragan-el-keraza": ["Users", "Paul", "Life", "Awards", "Mahragan El-Keraza"]
    }

    function handleUserCmd() {
        if ( !input ) { return setCommandError("") }

        // cd parent
        if ( input=="cd.." ) { cdParent() && setCommandError("") }

        // cd directory
        else if ( input.match(/^cd/) ) {
            if ( input.split("/").length-1 ) {
                let res = cdDir();
                res[0]? setRoute(res[1]) || setCommandError("") : setCommandError(res[1]);
            }
        }

        // invalid command
        else {
            let command = input.split(" ")[0];
            let errMsg = `${command} : The term '${command}' is not recognized as the name of a cmdlet, function, script file, or operable program. Check the spelling of the name, or if a path was included, verify that the path is correct and try again.\nAt line:1 char:1\n+ ${input}\n+ ${"~".repeat(command.length)}\n\t+ CategoryInfo          : ObjectNotFound: (${command}:String) [], CommandNotFoundException\n\t+ FullyQualifiedErrorId : CommandNotFoundException`;
            setCommandError(errMsg);
        }

        return setInput("");
    }

    // handle input: cd..
    function cdParent() {
        if ( route.length == 1 ) { return 0 }   // defines upper directory bound
        setRoute(routeTo(1).route);
        return 1;
    }

    // handle input: cd ./<dir>/
    function cdDir() {
        let errMsg;
        let cDir = routeTo();
        let goto = input.split("/").filter(e => e);
        goto.shift();
        goto.forEach( path => {
            if ( cDir.hasOwnProperty(path) ) { cDir = cDir[path] }
            else {
                let reqPath = `C:\\${cDir.route.join("\\")}\\${path}`;
                errMsg = `cd : Cannot find path '${reqPath}' because it does not exist.\nAt line:1 char:1\n+ ${input}\n+ ${"~".repeat(input.length)}\n \t+ CategoryInfo          : ObjectNotFound: (${reqPath}:String) [Set-Location], ItemNotFoundException\n\t+ FullyQualifiedErrorId : PathNotFound, EmployeeOS.Terminal.Commands.SetLocationCommand`;
            }
        });
        return errMsg? [0, errMsg] : [1, cDir.route];
    }

    // uses route state to return current or parent directory structure
    function routeTo(skip=0) {
        let tempRoute = [...route];
        let tempDir = directory;
        while ( tempRoute.length > skip ) {
            tempDir = tempDir[tempRoute.shift()]
        }
        return tempDir;
    }

    // onMount sets component scroll positions
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    // onWindowScroll
    useEffect(() => {
        // do nothing if enter has been pressed
        if ( enter ) { return debounce(() => setEnter(null), 1000)  }

        let rDist = subDir.map(ele => Math.abs(document.getElementById(ele).getBoundingClientRect().y));
        let newRoute = Object.values(routing)[rDist.indexOf(Math.min(...rDist))];
        !isEqual(route, newRoute) && setRoute(newRoute);
    }, [vScroll])

    // onKeyPress >> Enter
    useEffect(() => {
        handleUserCmd();
    }, [enter])

    useEffect(() => {
        // do nothing if enter has not been pressed
        if ( !enter ) { return }

        window.scrollTo({
            top: scrollPosition[[...route].pop()],
            behavior: "smooth",
        });
    }, [route]);

    // onWindowResize
    useEffect(() => {
        setScrollPosition({
            "Users": 0,
            "Paul": 0,

            "Experience": document.getElementById('Experience').getBoundingClientRect().y,
            "Aeyesafe": document.getElementById('aeyesafe').getBoundingClientRect().y,
            "Stauffer's of Kissel Hill": document.getElementById('stauffers').getBoundingClientRect().y,

            "Projects": document.getElementById('Projects').getBoundingClientRect().y,
            "Github": document.getElementById('Github').getBoundingClientRect().y,
            "CodePen": document.getElementById('CodePen').getBoundingClientRect().y,
            "Replit": document.getElementById('Replit').getBoundingClientRect().y,

            "Education": document.getElementById('Education').getBoundingClientRect().y,
            "Temple University": document.getElementById('temple').getBoundingClientRect().y,
            "Manheim Township High School": document.getElementById('mtwp').getBoundingClientRect().y,

            "Certifications": document.getElementById('Certifications').getBoundingClientRect().y,
            "freeCodeCamp": document.getElementById('freecodecamp').getBoundingClientRect().y,

            "Life": document.getElementById('Life').getBoundingClientRect().y,
            "Hobbies": document.getElementById('Hobbies').getBoundingClientRect().y,
            "Cooking": document.getElementById('cooking').getBoundingClientRect().y,
            "Awards": document.getElementById('Awards').getBoundingClientRect().y,
            "Mahragan El-Keraza": document.getElementById('mahragan-el-keraza').getBoundingClientRect().y
        })
    }, [windowSize])


    // scroll EventListener
    useEffect(() => {
        document.addEventListener( "scroll", throttle(() => setVScroll(window.pageYOffset), 300, {leading: false}) );
        return () => document.removeEventListener( "scroll", throttle(() => setVScroll(window.pageYOffset), 300, {leading: false}) );
    }, [])

    // enter EventListener
    useEffect(() => {
        document.addEventListener( "keypress", (e) => e.key == "Enter" && setEnter(e.timeStamp) );
        return () => document.removeEventListener( "keypress", (e) => e.key == "Enter" && setEnter(e.timeStamp) );
    }, [])

    // enter EventListener
    useEffect(() => {
        window.addEventListener( "resize", throttle(e => setWindowSize(e.timeStamp), 500), {leading: false} );
        return () => window.removeEventListener( "resize", throttle(e => setWindowSize(e.timeStamp), 500), {leading: false} );
    }, [])

    return (
        <div>
            <Home />
            <Console {...{ route, input, setInput, commandError, setCommandError }} />
        </div>
    )
}

export default App;
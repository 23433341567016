
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const compPics = importAll(require.context('../../public/Resources/mahragan el-keraza', false, /\.(png)$/));


export default function Awards() {
    return (
        <div id="Awards">
            <h3>Awards</h3>
            <div id="mahragan-el-keraza" className="section">
                <h4>Mahragan El-Keraza</h4>
                <p>A state-level competition held yearly between all Coptic Orthodox Churches in the Diocese of Pennsylvania, Maryland, Delaware, and West Virginia.</p>
                <p></p>
                <div id="comp-pics" className="row">
                    <div id="comp1" className="col-4 col">
                        <div className="row text-center">
                            <p>July 2018</p>
                        </div>
                        <div className="row row">
                            <div className="col">
                                <img src={compPics['comp1pic1.png']} className="img-fluid rounded" alt="Competition 1 Group Picture 1" />
                            </div>
                        </div>
                    </div>
                    <div id="comp2" className="col-8 col">
                        <div className="row text-center">
                            <p>July 2019</p>
                        </div>
                        <div className="row row">
                            <div className="col-6 col">
                                <div className="row row">
                                    <div className="col">
                                        <img src={compPics['comp2pic1.png']} className="img-fluid rounded" alt="Competition 2 Group Picture 1" />
                                    </div>
                                </div>
                                <br />
                                <div className="row row">
                                    <div className="col">
                                        <img src={compPics['comp2pic2.png']} className="img-fluid rounded" alt="Competition 2 Group Picture 2" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <img src={compPics['comp2pic3.png']} className="img-fluid rounded" alt="Competition 2 Group Picture 3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
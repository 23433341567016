import EducationEntry from "./EducationEntry";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const eduPics = importAll(require.context('../../public/Resources/education', false, /\.(jpg)$/));

export default function Education() {
    return (
        <div id="Education" className="section">
            <h3>Education</h3>
            <EducationEntry {...{
                id: "temple",
                img: { src: "./Resources/education/temple.svg", alt: "Manheim Township High School Front of Building" },
                institution: "Temple University",
                location: "Philadelphia",
                degree: "Computer Science B.S.",
                startDate: "Aug 2022",
                endDate: "May 2025",
                gpa: "3.88",
                courses: [
                    { name: "Mathematical Concepts in Computing I", number: "CIS 1966" },
                    { name: "Linear Algebra", number: "Math 2101" },
                    { name: "Calculus III", number: "Math 2943" }
                ],
                affiliations: "Association for Computing Machinery"
            }} />
            <EducationEntry {...{
                id: "mtwp",
                img: { src: "./Resources/education/mtwp.png", alt: "Manheim Township High School Front of Building" },
                institution: "Manheim Township High School",
                location: "Lancaster",
                degree: "High School Diploma",
                startDate: "Aug 2018",
                endDate: "Jun 2022",
                gpa: "3.956",
                desc: [
                    "3 AP Classes / 7 IB Classes"
                ]
            }} />
        </div>
    )
}

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const foodPics = importAll(require.context('../../public/Resources/cooking', false, /\.(jpg)$/));


export default function Hobbies() {
    return (
        <div id="Hobbies">
            <h3>Hobbies</h3>
            <div id="cooking" className="section">
                <h4>Cooking</h4>
                <div id="food-pics" className="col d-flex flex-column align-items-center">
                    <div className="row-6 row">
                        <div className="col-4">
                            <img src={foodPics['food1.jpg']} className="img-fluid rounded" alt="Meat Cubes w/ Rice & Yell Bell Peppers" />
                        </div>
                        <div className="col-4">
                            <img src={foodPics['food2.jpg']} className="img-fluid rounded" alt="Scrambled Eggs on Toast w/ Bacon" />
                        </div>
                        <div className="col-4">
                            <img src={foodPics['food3.jpg']} className="img-fluid rounded" alt="Swedish Meatballs" />
                        </div>
                    </div>
                    <br />
                    <div className="row-6 row">
                        <div className="col-4 d-flex align-items-center">
                            <img src={foodPics['food4.jpg']} className="img-fluid rounded" alt="Meatball Sandwich w/ White Sauce" />
                        </div>
                        <div className="col-4">
                            <img src={foodPics['food5.jpg']} className="img-fluid rounded" alt="Chicken Wrap" />
                        </div>
                        <div className="col-4">
                            <img src={foodPics['food6.jpg']} className="img-fluid rounded" alt="Blooming Bacon-Potato Flowers" />
                        </div>
                    </div>
                    <br />
                    <h6>Website Coming Soon!</h6>
                </div>
            </div>
        </div>
    )
}
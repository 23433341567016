// Components
import ProjectEntry from "./ProjectEntry"

export default function CodePen() {

    return (
        <div id="CodePen" className="section">
            <h4>CodePen</h4>
            <div className="d-flex justify-content-center">
                <ProjectEntry {...{
                    id: "calculator",
                    name: "Calculator",
                    link: { href: "https://codepen.io/PaulPresident/full/XWeqzoK", title: "Click to view project on CodePen" },
                    img: { src: "./Resources/Projects/CodePen/calculator.png", alt: "Calculator" }
                }} />
                <ProjectEntry {...{
                    id: "drum-machine",
                    name: "Drum Machine",
                    link: { href: "https://codepen.io/PaulPresident/full/NWaYMJp", title: "Click to view project on CodePen" },
                    img: { src: "./Resources/Projects/CodePen/drum-machine.png", alt: "Drum Machine" }
                }} />
            </div>
        </div>
    )
}
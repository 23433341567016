// Components
import NavBar from '../NavBar/NavBar';
import Contact from '../Contact/Contact';
import Welcome from '../Welcome/Welcome';
import Life from '../Life/Life';
import Experience from '../Experience/Experience';
import Projects from '../Projects/Projects';
import Education from '../Education/Education';
import Certifications from '../Certifications/Certifications';

export default function Home() {

    return (
        <div id="Home" className="d-flex flex-row w-100">
            <NavBar />
            <div className="mx-4">
                <Contact />
                <Welcome />
                <Experience />
                <Projects />
                <Education />
                <Certifications />
                <Life />
            </div>
        </div>
    )
}
export default function Certifications() {
    return (
        <div id="Certifications" className="section">
            <h3>Certifications</h3>
            <div id="aws">
                <h4>AWS Certifications</h4>
                <ul>
                    <li><a href="https://www.credly.com/badges/09ec4e65-9f41-4677-8bec-f2dc8a79d25e/linked_in_profile" target="_blank">AWS Certified Solutions Architect Associate</a></li>
                </ul>
            </div>
            <div id="freecodecamp">
                <h4>freeCodeCamp Certifications</h4>
                <ul>
                    <li><a href="https://www.freecodecamp.org/certification/fcce11beccb-8dac-4196-96b9-45709dfa11e3/responsive-web-design" target="_blank">Responsive Web Design</a></li>
                    <li><a href="https://www.freecodecamp.org/certification/fcce11beccb-8dac-4196-96b9-45709dfa11e3/javascript-algorithms-and-data-structures" target="_blank">Javascript Algorithms and Data Structures</a></li>
                    <li><a href="https://www.freecodecamp.org/certification/fcce11beccb-8dac-4196-96b9-45709dfa11e3/front-end-development-libraries" target="_blank">Front End Development Libraries</a></li>
                    <li><a href="https://www.freecodecamp.org/certification/fcce11beccb-8dac-4196-96b9-45709dfa11e3/quality-assurance-v7" target="_blank">Quality Assurance</a></li>
                </ul>
            </div>
        </div>
    )
}
import { useState, useEffect } from "react";
import { debounce } from "underscore";
import "./Welcome.css";

export default function Welcome() {
    const [start, setStart] = useState(true);

    useEffect(() => {
        debounce(() => setStart(false), 2750)();
    }, [])

    return (
        <div id="Welcome">
            <div className="d-flex align-items-center justify-content-center vh-100">
                <h1 className={start && "start"}>I'm Paul.</h1>
            </div>
        </div>
    )
}

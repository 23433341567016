// Components
import Awards from "./Awards";
import Hobbies from "./Hobbies";

export default function Life() {
    return (
        <div id="Life">
            <Hobbies />
            <Awards />
        </div>
    )
}
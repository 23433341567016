//Components
import ProjectEntry from "./ProjectEntry"

export default function Replit() {

    return (
        <div id="Replit" className="section">
            <h4>Replit</h4>
            <div className="d-flex justify-content-center">
                <ProjectEntry {...{
                    id: "sudoku-solver",
                    name: "Sudoku Solver",
                    link: { href: "https://replit.com/@PoulaSedra1/boilerplate-project-sudoku-solver", title: "Click to view project on Replit" },
                    img: { src: "./Resources/Projects/Replit/sudoku-solver.png", alt: "Sudoku Solving Algorithm" }
                }} />
                <ProjectEntry {...{
                    id: "issue-tracker",
                    name: "Issue Tracker",
                    link: { href: "https://replit.com/@PoulaSedra1/boilerplate-project-issuetracker", title: "Click to view project on Replit" },
                    img: { src: "./Resources/Projects/Replit/issue-tracker.png", alt: "Issue Tracker & Ticketing System" }
                }} />
            </div>
        </div>
    )
}
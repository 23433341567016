export default function Contact() {
    return (
        <div id="Contact" className="h-100">
            <ul id="social-media-links" className="list-unstyled d-flex flex-column">
                <a href="https://www.linkedin.com/in/paulsedra/" target="_blank" title="LinkedIn"><i className="fa fa-xl fa-linkedin"></i></a>
                <a href="https://github.com/PaulPresident" target="_blank" title="Github"><i className="fa fa-xl fa-github"></i></a>
                <a href="mailto:paulm.sedra@gmail.com" target="_blank" title="Email"><i className="fa fa-xl fa-envelope"></i></a>
                <a href="/Resources/Resume.pdf" target="_blank" title="Download CV&#013;Opens new tab"><i className="fa fa-xl fa-download"></i></a>
            </ul>
        </div>
    )
}